@font-face {
  font-family: 'Quicksand';
  src: local('Quicksand'), local('Quicksand-Regular'),
    url('assets/fonts/Quicksand/Quicksand-Regular.ttf') format('truetype');
  font-weight: normal;
}
@font-face {
  font-family: 'Quicksand';
  src: local('Quicksand'), local('Quicksand-Bold'),
    url('assets/fonts/Quicksand/Quicksand-Bold.ttf') format('truetype');
  font-weight: bold;
}
@font-face {
  font-family: 'Quicksand-Bold';
  src: local('Quicksand'), local('Quicksand-Bold'),
    url('assets/fonts/Quicksand/Quicksand-Bold.ttf') format('truetype');
  font-weight: bold;
}
@font-face {
  font-family: 'Quicksand';
  src: local('Quicksand'), local('Quicksand-SemiBold'),
    url('assets/fonts/Quicksand/Quicksand-SemiBold.ttf') format('truetype');
  font-weight: 550;
}
@font-face {
  font-family: 'Quicksand-SemiBold';
  src: local('Quicksand'), local('Quicksand-SemiBold'),
    url('assets/fonts/Quicksand/Quicksand-SemiBold.ttf') format('truetype');
  font-weight: 550;
}

html,
body,
#root {
  height: 100%;
  font-family: 'Quicksand' !important;
  overflow: auto;
}

a {
  color: inherit;
  text-decoration: none;
}

a:visited {
  color: inherit;
}

ul {
  padding-left: 25px;
}
